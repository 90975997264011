import storageService from '../../services/storage';
import couponService from '../../services/coupon';

/**
 * Sets the treatment type in the local storage based on the hidden input when having multiple medications slider
 */
const setTreatmentType = () => {
  storageService().treatmentType = document.querySelector('#treatment_type').value;
};

/**
 * Helper function to add a value to the local storage
 *
 * @param {string} key   the key to store the value
 * @param {value}  value the value to store
 */
const addStorageValue = (key, value) => {
  const storage = storageService()[key];
  storage.push(value);
  storageService()[key] = storage;
};

/**
 * Adds the upsell to the local storage if the price is greater than 0
 *
 * @param {object} option the upsell option to add to the local storage
 */
const addUpsell = (option) => {
  if (parseInt(option.upsellPrice) <= 0) {
    return;
  }

  addStorageValue('orderUpsells', {
    id: parseInt(option.id),
    name: option.name,
    slug: option.slug,
    price: parseInt(option.price),
    upsell_price: parseInt(option.upsellPrice),
  });
};

/**
 * Adds the extra charge to the local storage if the price is greater than 0 or the flag is set to true
 *
 * @param {object}  answer    the extra charge option to add to the local storage
 */
const addExtraCharge = (answer) => {
  if (parseInt(answer.extraCharge) <= 0) {
    return;
  }

  addStorageValue('extraCharges', { name: answer.name, amount: parseInt(answer.extraCharge), type: 'Extra Charge' });
};

/**
 * Merges the answers name and slug. Sum the price and extra charge.
 *
 * @param {object} firstAnswer the first test option
 * @param {object} secondAsnwer the second test option
 */
const mergeAnswers = (firstAnswer, secondAsnwer) => {
  return {
    name: `${firstAnswer.name} - ${secondAsnwer.name}`,
    slug: `${firstAnswer.slug}-${secondAsnwer.slug}`,
    price: parseInt(firstAnswer.price) + parseInt(secondAsnwer.price),
    extraCharge: parseInt(firstAnswer.extraCharge) + parseInt(secondAsnwer.extraCharge),
  };
};

/**
 * Adds the test to the local storage and adds the extra charge when applicable
 *
 * @param {object} answer the test option to add to the local storage
 */
const addTest = (answer) => {
  addStorageValue('tests', { name: answer.name, slug: answer.slug, price: answer.price });
};

/**
 * Event listener for the multiple medications slider callback event
 */
document.addEventListener('MultipleTestsCallback', () => {
  const multipleTests = storageService().sliderAnswers;
  const answer = mergeAnswers(multipleTests.q1, multipleTests.q2);
  const upsell = multipleTests.q3;

  storageService().clearCheckoutData();
  setTreatmentType();
  addTest(answer);
  addExtraCharge(answer);
  addUpsell(upsell);
  couponService().applyDefaultCoupon();
  storageService().sliderAnswers = {};

  window.location.href = '/select-pharmacy';
});
