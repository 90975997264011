import './sliderEvents/multipleMedication';
import storageService from '../services/storage';

/**
 * Class to handle the slider component
 */
class RadioSlider {
  /**
   * Constructor for the RadioSlider class
   *
   * @param {string} sliderKey The slider key to initialize the slider component
   */
  constructor(sliderKey) {
    this.callbackEvent = `${sliderKey}Callback`;
    this.answers = {};
    this.currentSlide = 0;
    this.slider = document.querySelector(`#${sliderKey}.slider-container .slider`);
    this.slides = Array.from(this.slider.querySelectorAll('.slide'));
    this.prevBtns = this.slider.querySelectorAll('.prevBtn');
    this.nextBtns = this.slider.querySelectorAll('.nextBtn');
    this.options = this.slider.querySelectorAll('.option-container');
    this.singleOptionSlides = this.slides.filter((slide) => parseInt(slide.dataset.optionsCount) === 1);
    this.multipleOptionSlides = this.slides.filter((slide) => parseInt(slide.dataset.optionsCount) > 1);
    this.totalVisibleSlides = this.multipleOptionSlides.length;

    this.init();
  }

  /**
   * Initialize the slider component
   */
  init() {
    this.resetSliderAnswers();
    this.autoSaveSingleOptionResponses();
    this.addButtonEventListeners();
    this.addOptionEventListeners();
    this.hideNonNavigableSlides();
  }

  /**
   * Handle the visibility of non-navigable elements in the slider.
   *
   * This includes hiding the slides that contain only a single option and removing the back button from the first
   * visible slide in the navigation sequence.
   */
  hideNonNavigableSlides() {
    this.singleOptionSlides.forEach((slide) => (slide.style.display = 'none'));

    const firstVisibleSlide = this.multipleOptionSlides[0];
    if (!firstVisibleSlide) {
      return;
    }

    const backButton = firstVisibleSlide.querySelector('.prevBtn');
    if (!backButton) {
      return;
    }

    backButton.style.display = 'none';
  }

  /**
   * Handle single option slides by automatically saving their values
   */
  autoSaveSingleOptionResponses() {
    this.singleOptionSlides.forEach((slide) => {
      const radioInput = slide.querySelector('input[type="radio"]');
      if (radioInput) {
        this.saveSelectedOption(radioInput.name, radioInput);
      }
    });
  }

  /**
   * Add event listeners to the slider options
   */
  addOptionEventListeners() {
    this.options.forEach((option) => {
      option.addEventListener('click', () => {
        this.slider.classList.remove('has-error');
      });
    });
  }

  /**
   * Add event listeners to the slider buttons
   */
  addButtonEventListeners() {
    this.prevBtns.forEach((prevBtn) => {
      prevBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.movePrevSlide();
      });
    });

    this.nextBtns.forEach((nextBtn) => {
      nextBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.moveNextSlide();
      });
    });
  }

  /**
   * Move the slider to the previous slide
   */
  movePrevSlide() {
    this.currentSlide--;
    this.slider.classList.remove('has-error');
    this.updateSliderPosition();
  }

  /**
   * Move the slider to the next slide
   */
  moveNextSlide() {
    const currentSlide = this.multipleOptionSlides[this.currentSlide];
    const currentQuestion = `q${this.slides.indexOf(currentSlide) + 1}`;
    const selectedOption = document.querySelector(`input[name="${currentQuestion}"]:checked`);

    if (!selectedOption) {
      this.slider.classList.add('has-error');

      return;
    }

    this.saveSelectedOption(currentQuestion, selectedOption);

    if (this.isLastSlide()) {
      document.dispatchEvent(new CustomEvent(this.callbackEvent));

      return;
    }

    this.currentSlide++;
    this.updateSliderPosition();
  }

  /**
   * Reset the slider answers in the local storage
   */
  resetSliderAnswers() {
    storageService().sliderAnswers = {};
  }

  /**
   * Check if the current slide is the last slide
   *
   * @returns {boolean} true if the current slide is the last slide, false otherwise
   */
  isLastSlide() {
    return this.currentSlide === this.totalVisibleSlides - 1;
  }

  /**
   * Save the selected option in the local storage
   *
   * @param {string} currentQuestion the current question key
   * @param {RadioNodeList} selectedOption the selected option element
   */
  saveSelectedOption(currentQuestion, selectedOption) {
    this.answers[currentQuestion] = JSON.parse(selectedOption.value);
    storageService().sliderAnswers = this.answers;
  }

  /**
   * Update the slider position to the current slide
   */
  updateSliderPosition() {
    const position = -this.currentSlide * 100;

    this.slider.style.transform = `translateX(${position}%)`;
  }
}

window.RadioSlider = RadioSlider;
document.dispatchEvent(new CustomEvent('RadioSliderReady'));
