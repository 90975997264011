import * as Sentry from '@sentry/browser';

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn: 'https://9ea1a33f276705615ff0d80b1152fa64@o510915.ingest.us.sentry.io/4507606318841856',
  release: process.env.SENTRY_RELEASE,
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  tracePropagationTargets: ['www.starfish.local', 'starfish.com'],
  integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      order_id: localStorage.getItem('transaction_id') ?? null,
    };

    return event;
  },
  ignoreErrors: ['BraintreeError: Cannot contact the gateway at this time.'],
  denyUrls: [
    'app.wonderchat.io',
    'bat.bing.com',
    'cdn.wisepops.com',
    'cdn.ywxi.net',
    'googleads.g.doubleclick.net',
    'tools.luckyorange.com',
    'wisepops.net',
    'www.trustedsite.com',
  ],
});
